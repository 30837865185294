<template>
  <div>
    <Projects v-if="true">
      <b-card
        class="projects-card"
        align="left"
      >
        <b-row>
          <b-col>
            <h4>Proyectos</h4>
          </b-col>
          <b-col v-if="!isTechnician">
            <router-link
              :to="{ name: 'project-create' }"
              class="float-right"
            >
              <img :src="require('@/assets/icons/create-icon.svg')" alt="create-icon">
            </router-link>
          </b-col>
        </b-row>
        <b-row v-if="!projects || projects.length === 0">
          <b-col>
            <strong>¡Vaya!</strong>
            <div>Parece que no tienes proyectos creados</div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <b-container>
              <b-row v-for="project in this.sortedProjects()" :key="project.id" class="project-row align-items-center">
                <b-col>
                  <span :class="isFutureProject(project) ? 'future-project' : 'pass-project'">
                    {{ project.name }} | {{ project.startAt | moment }} - {{ project.endAt | moment }}
                  </span>
                </b-col>
                <b-col>
                  <span class="ml-auto edit-card-info-image" @click="onEditProject(project)"></span>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-card>
    </Projects>
  </div>
</template>

<script>
import Projects from '../../Projects.vue'
import moment from 'moment'
import Profiles from '@/utils/getProfiles.js'

export default {
  name: 'ProjectList',
  components: { Projects },
  async mounted () {
    if (this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS) {
      await this.$store.dispatch('project/getAll')
    } else {
      await this.$store.dispatch('project/getAllByTechnician')
    }
  },
  computed: {
    projects () {
      return this.$store.getters['project/all']
    },
    isTechnician () {
      return this.$store.state.currentProfile === Profiles.PROFILE_TECHNICIAN
    }
  },
  filters: {
    moment (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  methods: {
    onEditProject (project) {
      this.$router.push({
        name: 'edit-project',
        params: {
          projectId: project.id
        }
      })
    },
    isFutureProject (project) {
      const now = new Date()
      const date = new Date(project.endAt)
      return date.getTime() >= now.getTime()
    },
    compareDates (a, b) {
      const dateA = new Date(a.endAt).getTime()
      const dateB = new Date(b.endAt).getTime()
      if (dateA > dateB) return 1
      if (dateA < dateB) return -1
      return 0
    },
    sortedProjects () {
      if (this.projects) {
        const futureProjects = this.projects
          .filter(proj => this.isFutureProject(proj))
          .sort((a, b) => this.compareDates(a, b))
        const passProjects = this.projects
          .filter(proj => !this.isFutureProject(proj))
          .sort((a, b) => this.compareDates(a, b))
        return futureProjects.concat(passProjects)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.project-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 16px 0;
  span {
    color: white;
  }
}
.project-row:last-child {
  border-bottom: none;
}
.future-project {
  font-weight: 700;
}
.pass-project {
  font-weight: 300;
}
.edit-card-info-image {
  background-image: url("../../../assets/icons/edit_card.svg");
  width: 32px;
  height: 32px;
  display: block;
  cursor: pointer;
}
</style>
